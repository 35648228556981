import React from 'react'
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons'
import * as UI from '../styled-components'
import Grid4Columns from '../../layouts/Grid4Columns'
import * as AppUI from '../../app'
import { DISPLAY_PARTY_AS_OPTIONS, PARTY_1, PARTY_2 } from '../../../constants/case-parties'
const Parties = props => {
  const {
    activeTab,
    toggleActiveTab,
    party1,
    party2,
    handleSelectedParty,
    caseCaptionPartyOptions,
    dispatch
  } = props
  return (
    <div>
      <UI.ToggleSectionButton
      isActive={'PARTIES' === activeTab}
      onClick={() => toggleActiveTab('PARTIES')}>
      <UI.ToggleSectionIcon
      icon={'PARTIES' === activeTab ? faAngleDown : faAngleRight} /> Parties
      </UI.ToggleSectionButton>

      {'PARTIES' === activeTab && (
            <UI.BodySectionInner>
              {/* todo: replace with correct URL */}
              <UI.BodyInstructions>For each party subject to this order, select a name from the case profile. <a href="/">Edit case profile here.</a> </UI.BodyInstructions>
              <Grid4Columns>
                <AppUI.Input>
                  <label>Select from Case Profile</label>
                  <select
                    value={party1.caseProfileParty}
                    onChange={handleSelectedParty(PARTY_1)}>
                    <option value="" key="blank"> - Please select - </option>
                    {caseCaptionPartyOptions.map((opt, i) => (
                      <option value={opt.value} key={i}>{opt.label}</option>
                    ))}
                  </select>
                </AppUI.Input>

                <AppUI.Input>
                  <label>Display as</label>
                  <select
                    value={party1.displayAs}
                    onChange={e => {
                      dispatch({ type: 'SET_PARTY', party: PARTY_1,
                        update: { displayAs: e.target.value }
                      })
                    }}
                  >
                    <option
                      value="" key="blank"> - Please select - </option>
                    {DISPLAY_PARTY_AS_OPTIONS.map((opt, i) => (
                      <option value={opt.value} key={i}>{opt.label}</option>
                    ))}
                  </select>
                </AppUI.Input>
              </Grid4Columns>
              <Grid4Columns>
                <AppUI.Input>
                  <label>Select from Case Profile</label>
                  <select
                    value={party2.caseProfileParty}
                    onChange={handleSelectedParty(PARTY_2)}>
                    <option value="" key="blank"> - Please select - </option>
                    {caseCaptionPartyOptions.map((opt, i) => (
                      <option value={opt.value} key={i}>{opt.label}</option>
                    ))}
                  </select>
                </AppUI.Input>

                <AppUI.Input>
                  <label>Display as</label>
                  <select
                    value={party2.displayAs}
                    onChange={e => {
                      dispatch({ type: 'SET_PARTY', party: PARTY_2,
                        update: { displayAs: e.target.value }
                      })
                    }}
                  >
                    <option value="" key="blank"> - Please select - </option>
                    {DISPLAY_PARTY_AS_OPTIONS.map((opt, i) => (
                      <option value={opt.value} key={i}>{opt.label}</option>
                    ))}
                  </select>
                </AppUI.Input>
              </Grid4Columns>
            </UI.BodySectionInner>
          )}
    </div>
  )

}

export default Parties